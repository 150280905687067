#app-header {
  padding: 0;
  margin: 0;
  height: 120px;
}

#app-header-logo-content {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  background-color: #d0ff00;
}

#app-header-main-content {
  display: inline-block;
  height: 100%;
  background-color: #d0ff00;
}

#app-header-main-left {
  display: inline-block;
  width: 55%;
}

#app-header-client-name {
  margin-top: 10px;
  margin-left: 5%;
  color: black;
}

#app-header-nav {
  margin-top: 50px;
  margin-left: 5%;
}

#app-header-main-right {
  float: right;
  width: 45%;
}

#app-header-main-top-right {
  float: right;
  width: 100%;
  margin-top: 10px;
  text-align: right;
}

#app-header-search-input {
  width: 60%;
  vertical-align: 6px;
  margin-right: 2%;
  border-style: solid;
}

#app-header-logout-btn,
#app-header-logout-btn:active {
  position: absolute;
  right: 0px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: none;
  padding: 10px;
  color: #888;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  z-index: 1000;
  left: auto;
}

.app-header-nav-item {
  display: inline-block;
  font-size: 18px;
  margin-right: 5%;
  color: #ffffff !important;
}

.app-header-icons {
  margin-right: 2%;
  color: black !important;
}

a,
a:hover,
button {
  cursor: pointer;
  text-decoration: none;
}

#app-header-logo-content a {
  color: black !important;
}

#app-header-logo-text {
  font-size: 60px;
}
