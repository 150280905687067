/*
  This file contains base variables that can be imported
  and used throughout the css files.
*/

/* Lockstep Default Colors */
$blue-black: #0a212f;
$dark-navy: #0f3044;
$gray1: #3e5969;
$gray2: #576e7c;
$gray3: #6f828e;
$gray4: #dbe0e3;
$gray5: #f3f4f5;
$gray6: #f8fbfc;
$gray7: #98a6af;
$purple: #7d4ed8;
$dark-purple: #5727b2;
$pink: #ef3c6b;
$red: #f8063f;
$green: #46a72a;
$orange: #f38923;
$yellow: #edbb00;
$turquoise: #1fb6c1;
$turquoise-light: #bbe9ec;

$turquoise-bg-lightest: rgba($turquoise, 0.5);
$turquoise-bg-light: rgba($turquoise, 0.7);

/* Useful variables*/
$text-default-color: $dark-navy;
$text-inverse-color: #ffffff;

$white-background-color: #ffffff;
$off-white-background-color: $gray6;

$link-color: $turquoise;
$link-hover-color: $turquoise-light;

$editor-width: 44rem;

$card-padding: 2.6rem;
