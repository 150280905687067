@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "base";
@import "./_checkbox";
@import "./scriptManager.scss";

html {
  height: 100%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #333333;
}
/* Override Bootstraps defaults styles. */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
textarea,
div,
li,
nav {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

.main-flood-color-bg {
  background-color: #31333f;
  color: #ffffff;
}

.teal-color-bg {
  background-color: #00c9b2;
}

.dark-grey-font {
  color: #999999;
}

.light-gray-font {
  color: #dddddd;
}

.teal-color-font {
  color: #00c9b2;
}

.light-gray-bg {
  background-color: #dddddd;
}

.dark-grey-bg {
  background-color: #999999;
}

.warning-color-font {
  color: #fe6a6a;
}

.warning-color-bg {
  background-color: #fe6a6a;
  color: #ffffff;
}

.notification-color-font {
  color: #695dfa;
}

.transparent-bg {
  background: rgba(0, 0, 0, 0);
}

.modal-content {
  outline: none !important;
  border-color: #4b4e61 !important;
}

input,
Form > div > input,
.input-defualt {
  border-radius: 0% !important;
  background-color: #ffffff !important;
  color: black !important;
  padding: 5px;
}

input:focus {
  outline: none;
  box-shadow: none;
}

#app-nav {
  min-height: 100vh;
}

@media (max-width: 768px) {
  #app-nav {
    min-height: 0px;
  }
}

#app-nav a {
  color: #fff;
  font-size: 1em;
  padding: 0.5rem 1rem;
  display: block;
}

#app-nav a:hover {
  color: #fff;
  font-size: 1em;
  padding: 0.5rem 1rem;
  display: block;
  background: #00d9c2;
}

#app-nav a.active {
  background: rgb(8, 155, 160);
}

button,
.btn,
.btn-secondary {
  border: none;
  border-radius: 0;
  padding: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

button,
.btn {
  background-color: #00c9b2;
}

.btn-secondary {
  background-color: #31333f;
}

button:focus,
.btn:focus,
.btn-secondary:focus {
  outline: none;
  box-shadow: none;
  background-color: #14c7c1;
}

button:hover,
.btn:hover,
.btn-secondary:hover {
  background-color: #14c7c1;
}

button:active,
.btn:active,
.btn-secondary:active {
  position: relative;
  left: 2px;
  top: 2px;
  background-color: #14c7c1 !important;
}

.btn-danger,
.btn-danger:active {
  background-color: #c82333 !important;
}

.inactive-button,
.inactive-button:hover {
  border-color: #00c9b2;
  background-color: #00c9b2;
}

.active-button,
.active-button:hover {
  border-color: #00c9b2;
  background-color: #00c9b2;
}

/* HITCH CSS FRAMEWORK */
.h-full-without-header {
  height: calc(100vh - 120px);
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
}

.clearfix:after {
  clear: both;
}

/* END OF HITCH CSS FRAMEWORK */

.main-content-width {
  display: inline-block;
  width: calc(100% - 275px);
  padding: 5px;
}

.light {
  color: #ffffff;
}

.navbar.navbar-light ul li {
  color: #ffffff;
  margin-bottom: 1px;
}

.isActiveLink {
  background-color: #00c9b2;
  color: #31333f;
}

.main-nav ul {
  display: block;
  width: 100%;
}

.grants ul li {
  display: block;
  height: 100%;
}

.grants ul {
  display: block;
}

.signUpBtn {
  padding: 1%;
  border-radius: 2%;
  background-color: #31333f;
  color: #ffffff;
  font-size: 1em;
}

.loginModal div {
  background-color: #31333f;
  color: #ffffff;
}

.modal-dialog .signUpModal {
  padding: 0;
  margin: 0;
  overflow: auto;
}

.row .vcenter {
  align-items: center;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white !important;
}

.fa-spinner {
  /* border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px; */
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* LOGIN PAGE CSS */
#login-container {
  background-color: #00c9b2;
  min-height: 100vh;
  position: absolute;
  width: 100%;
}

#app-container {
  background-color: #00c9b2;
  min-height: 100%;
  width: 100%;
}

#app-content {
  background-color: #fff;
}

#login-logo,
#app-logo {
  width: 156px;
  height: 21px;
  margin: 1rem;
}

#login-subcontainer {
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
}

#login-left-container {
  padding: 5px;
  padding-bottom: 0;
}

#login-right-container {
  padding: 5px;
  padding-bottom: 0;
  color: #999999;
}

#login-right-container input .form-control {
  border-color: #999999;
  color: #999999 !important;
}

#login-save-signin-container {
  text-align: left;
  padding-bottom: 10px;
}

#login-login-btn {
  display: block;
  width: 100%;
  margin: 0 auto;
  color: black;
}

#login-forgot-password {
  display: block;
  padding-top: 10px;
  text-align: center;
}

#login-login-footer {
  padding: 0;
}

#login-Logo-Text {
  text-align: center;
  font-weight: 900;
  color: black;
  padding-top: 50px;
}

#login-signup-container {
  margin: 0;
  padding-top: 100px;
}

#login-signup-link {
  color: #00c9b2;
}

.headerDivider {
  display: inline-block;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  margin-left: 2.5%;
  width: 0.025%;
  height: 320px;
}

/* MAIN CONTENT SECTION */

.main-content-body {
  padding: 15px;
}

/* VENDOR VIEW SECTION */

.badge-purple {
  background-color: #695dfa;
  color: #ffffff;
  font-weight: normal;
  font-size: medium;
  padding: 0.15em 0.5em;
}

.inline-block-right {
  display: inline-block;
  text-align: right;
}

input.plain {
  border-style: solid;
  border-color: #dddddd;
}

input.thin {
  border-width: 1px;
}

input.notop {
  border-top-style: none;
  border-top-width: 0px;
}

input.nobottom {
  border-bottom-style: none;
  border-bottom-width: 0px;
}

.btn.btn-attachment {
  background-color: white;
  color: black;
  border: 1px solid;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.btn.btn-light {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  text-align: left;
}

.btn.btn-gray {
  background-color: lightgray;
  color: black;
  border: none;
  cursor: pointer;
  text-align: left;
}

/* OVERRIDE BOOTSTRAP CSS */

.modal-backdrop {
  z-index: 1999;
}

.modal {
  z-index: 2000;
}

.modal-dialog.v-center-modal {
  max-width: 600px;
  padding-top: 10%;
}

.v-center-modal > .modal-content {
  border-radius: 0;
}

.panel-title {
  padding: 1rem;
}

.panel-tabs {
  border-bottom: 1px solid #bbb;
}

.feature-groups a {
  padding: 0.5rem 1rem;
  display: inline-block;
  color: #31333f;
}

.feature-groups a.active {
  border-bottom: 2px solid #00c9b2;
}

.feature-group-title {
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid #00c9b2;
  margin: 0px;
}

.feature-group-title .btn-group {
  float: right;
  margin: -0.45rem 0rem 0rem 1rem;
}

.feature-description .btn-group {
  float: right;
  margin: 0.4rem 1rem 0rem 0rem;
}

.feature-description .btn-group .btn {
  padding: 0.2rem 0.6rem;
}

.feature-group-empty {
  padding: 1rem;
  color: #888;
  font-style: italic;
  border-bottom: 1px solid #bbb;
}

.feature-group-list-toolbar {
  padding: 1rem;
}

.btn-group .btn:not(:first-child) {
  border-left: 1px solid #00b9a2;
}

.btn-group .btn.active:not(:first-child) {
  border-left: 1px solid #00b9a2;
}

.feature {
  color: #888;
}

.feature-title {
  padding: 0.5rem 1rem;
  margin: 0px;
  color: #000;
  display: inline-block;
}

.feature:nth-child(2n + 1) {
  background: #f0f0f0;
}

.feature:last-child {
  border-bottom: 1px solid #bbb;
}

.feature-description {
  padding-left: 0.5em;
}

.subscription-type-feature-list .row {
  text-align: center;
}

.subscription-type-feature-list .col {
  height: 6rem;
}

.subscription-type-feature-list th:not(:first-child) {
  font-size: 1.2em;
}

.subscription-type-feature-list th:nth-child(2n) {
  background: #f0f0f0;
}

.subscription-type-feature-list th:nth-child(2n + 1) {
  background: #e0e0e0;
}

.subscription-type-header {
  height: 6rem;
  text-align: center;
  font-weight: normal;
}

.subscription-type-feature-group-name {
  font-size: 1.2em;
  border-bottom: 1px solid #00c9b2;
  background: #ffffff;
}

.subscription-type-feature {
  text-align: center;
  min-width: 6rem;
}

.customers-panel,
.list-panel {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18), 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 1rem;
}

.customers-panel-title,
.list-panel-title {
  font-weight: 300;
  font-size: 1.4em;
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
}

.customers-panel-heading.customers-panel-actions {
  margin-right: 0.57rem;
}

.customers-panel-actions {
  text-align: right;
}

.customers-panel-actions .fas {
  color: #00c9b2 !important;
}

.customers-panel-actions a {
  color: #00c9b2 !important;
}

.customers-panel-collapsed,
.list-panel-item {
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
}

.list-panel-item-condensed {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eaeaea;
}

.list-panel-item-condensed-selected {
  background: #f0f0f0;
}

.list-panel-item-toolbar {
  padding: 0.5rem 1rem;
  background: #333;
  color: #e0e0e0;
}

.list-panel-tabs {
  position: relative;
}

.list-panel-item-toolbar * {
  padding-right: 1rem;
}

.list-panel-item-toolbar span:hover {
  color: #ffffff;
}

.list-panel-item-toolbar span.inactive:hover {
  color: #666666;
}

.list-add,
.list-delete {
  float: right;
  letter-spacing: 0.08em;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-variant: small-caps;
  font-weight: 700;
}

.list-add {
  color: #00c9b2 !important;
  line-height: 2.8em;
}

.list-delete {
  color: #e32424 !important;
  line-height: 2em;
}

.list-search {
  border: 0px none;
  border-bottom: 1px solid #eeeeee;
}

.customers-panel-expanded {
  padding: 0rem;
  outline: 2px solid #00c9b2;
}

.customers-panel-expanded > .row {
  padding: 1rem;
}

.customers-panel-expanded-row {
  background: #f9f9fb;
  padding: 0.5rem 1rem;
  border-top: 1px solid #eeeeee;
}

.customers-panel-customer-name,
.migration-panel-account-name {
  font-weight: 400;
  font-size: 1.1em;
  margin-bottom: 0.3rem;
}

.customers-panel-heading,
.panel-item-heading,
.panel-item-label {
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 0.7em;
  text-transform: uppercase;
  font-variant: small-caps;
  color: #666666;
}

.customers-panel-heading a {
  text-decoration: underline !important;
  color: #666666;
}

.customers-panel-heading:first-child {
  margin-bottom: 0.47rem;
}

.customers-panel-value {
  margin-top: 0.3rem;
}

.customers-panel-value span {
  margin-right: 0.6rem;
}

.customers-panel-value span.fas {
  color: #666666;
}

.customers-panel-value-mono {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace !important;
}

.inactive {
  color: #666666;
}

.valid {
  color: #22a900 !important;
}

.invalid {
  color: #e32424 !important;
}

.outgoing-value {
  font-weight: 900;
}

.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace !important;
}

.editor-tabs.nav {
  display: inline-flex !important;
  right: 0px;
  position: absolute;
  border-bottom: 0px none;
  padding: 0px;
}

.editor-name {
  border: 0px none;
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
  margin-right: 0.6rem;
}

.editor-tabs .nav-link.active {
  background: #aaaaaa;
  border: 0px none;
}

.editor-tabs .nav-link {
  background: #6f6f6f;
  border-radius: 0px;
  border: 0px none;
  font-size: 0.8rem;
  font-variant: all-small-caps;
  font-weight: 800;
  color: #333333;
  padding: 0.2rem 0.8rem;
}

.editor-add-attachment {
  padding-right: 0px;
}

.centered {
  text-align: center;
}

.big-icon {
  font-size: 4rem;
}

.editor-attachment-image {
  width: 70px;
  height: 70px;
  overflow: hidden;
  display: inline-block;
  background: #f0f0f0;
  border: 1px solid #ddd;
  margin: 6px;
  line-height: 65px;
  position: relative;
}

.editor-attachment-image img {
  max-width: 100%;
  max-height: 100%;
}

.editor-attachment-image span {
  position: absolute;
  top: 4px;
  right: 4px;
}

.connector-icon {
  max-width: 100%;
}

.panel-connector-icon {
  text-align: center;
}

.panel-connector-name {
  font-size: 1.2rem;
  padding: 0rem 1rem !important;
}

.panel-connector-unique-name {
  margin-left: 1rem;
  color: #888;
  font-style: italic;
}

.switch-button-container {
  width: 3rem;
  border-radius: calc(0.75rem + 1px);
  background: #f9f9f9;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08), inset 0px 2px 7px rgba(0, 0, 0, 0.18);
}

.switch-button {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18), 0px 2px 7px rgba(0, 0, 0, 0.25);
}

.switch-button-container-on {
  box-shadow: inset 0px 4px 10px rgba(20, 60, 0, 0.08), inset 0px 2px 7px rgba(20, 60, 0, 0.18);
  background: #88d900;
}

.switch-button-on {
  margin-left: 1.5rem;
}

.panel-item-heading {
  margin-bottom: 1rem;
}

.panel-item-label {
  margin-top: 1rem;
}

.panel-item-id {
  margin: 0px;
  font-size: 0.8rem;
}

.list-panel-button {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  border: 1px solid #ddd;
  text-align: center;
}

.list-panel-item .list-panel-button {
  margin-top: 1.5rem;
}

.list-panel-title .list-panel-button {
  margin-right: 1rem;
}

.panel-item-input input {
  border: 1px solid #ddd;
  border-radius: 0.5rem !important;
  padding: 3rem 0.8rem 1.5rem 0.7rem !important;
  width: 95%;
}

.panel-item-input .panel-item-label {
  margin: 0 0 0 0;
  position: absolute;
  top: 0.5rem;
  left: 0.8rem;
}

.invalid-feedback {
  margin-top: 0.5rem;
}

.connector-icon-upload,
.connector-button-upload {
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 1rem;
}

.connector-icon-upload {
  margin-right: 1rem;
}

.connector-icon-upload img,
.connector-button-upload img {
  padding: 1rem;
  width: 100%;
}

.connector-company-id-input {
  font-family: monospace;
  width: 98.7% !important;
}

.connector-company-id-input .panel-item-label {
  left: 1.8rem !important;
}

.list-panel-section {
  border-left: 1px solid #eaeaea;
  padding: 1rem;
}

.list-panel-section-thin {
  border-left: 1px solid #eaeaea;
  min-height: 30rem;
}

.panel-item-input-right {
  margin-left: 5% !important;
}

.connector-fullwidth-input {
  width: 100% !important;
}

.list-panel-item-thin {
  border-bottom: 1px solid #eaeaea;
}

.panel-item-label-with-gutters {
  left: 1.8rem !important;
}

.list-panel-section-thin .list-panel-section-thin:first-child {
  border-left: none;
}

.list-panel-center-title {
  text-align: center;
  padding: 1rem;
  text-transform: uppercase;
  font-variant: small-caps;
  font-weight: 300;
  letter-spacing: 0.08em;
}

.resource-mappings {
  padding: 2rem 0rem;
  text-align: center;
}

.connector-resource,
.connector-resource-target,
.connector-resource-not-selected,
.connector-action {
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-variant: small-caps;
  font-weight: 700;
  letter-spacing: 0.08em;
  margin: 1rem;
  display: inline-block;
}

.connector-resource {
  background: #00c9b2;
}

.connector-resource-target {
  background: #333333;
}

.connector-action {
  background: #695dfa;
}

.connector-resource-not-selected {
  border: 1px solid #333333;
  color: #333;
}

.list-panel-item-bottom {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 2rem;
}

.resource-mappings .list-add {
  float: none;
}
.unprocessed-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .unprocessed-text-input {
    margin-right: 1rem;
    label {
      margin-right: 1rem;
    }
  }
}

.unnasigned-entities-error {
  display: flex;
  border: solid 1px #ff0000;
  width: 15rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  color: #ff0000;
  text-align: center;
  justify-content: center;
  align-items: center;

  .fa-exclamation-triangle {
    font-size: 0.8rem;
  }
  span {
    margin-left: 0.5rem;
  }
}

.tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  .tab-header {
    color: #808080;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;

    &:hover {
      color: #444444;
    }

    &__active {
      @extend .tab-header;
      color: white;
      background-color: #00c9b2;
      border-radius: 5px;

      &:hover {
        color: white;
      }
    }
  }
}

.control-panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  .control-panel-card {
    display: flex;
    border: solid 1px #e2e2e2;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    height: 500px;
    width: 1000px;

    .switch-guard {
      height: 13rem;
      width: 12rem;
      position: absolute;
      background-color: #808080;
      opacity: 0.25;
      top: 14rem;
      border: solid 1px black;
      border-bottom: solid 10px black;
      transition: 1s;

      &__active {
        @extend .switch-guard;
        transition: 1s;
        transform: translateY(-200px);
      }
    }

    .switch-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;

      .switch {
        display: inline-block;
        padding: 0.5em 1em;
        text-decoration: none;
        background: #00c9b2;
        color: #fff;
        font-size: 1.2rem;
        height: 10rem;
        width: 10rem;
        border-bottom: solid 5px #008172;
        border-radius: 50%;

        &:active {
          -ms-transform: translateY(4px);
          -webkit-transform: translateY(4px);
          background-color: #00c9b2;
          transform: translateY(4px);
          border-bottom: none;
        }
      }

      p {
        margin: 0;
        background-color: #f00;
        color: #fff;
        border: solid 1px #f00;
        border-radius: 5px;
        padding-left: 1rem;
        padding-right: 1rem;
        font-family: "Courier New", Courier, monospace;

        &.enabled {
          color: #fff;
          background-color: #00b300;
          border: solid 1px #00b300;
        }
      }
    }
  }
}

.migration-panel {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18), 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  margin: -1rem 1rem;
  height: 90vh;
  display: flex;
}

.migration {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.migration-list {
  overflow-y: scroll;
  flex: 1;
}

.migration-panel-title {
  padding: 1rem;
  height: 10vh;
}

.migration-loading {
  height: 90vh;
  overflow: hidden;
  position: absolute;
  width: 81vw;
}

@keyframes slide {
  from {
    transform: rotate(-30deg) translate(0, 0);
  }
  to {
    transform: rotate(-30deg) translate(3000%, 0);
  }
}

@keyframes spinny {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(360deg);
  }
  90% {
    transform: rotate(1800deg);
  }
  100% {
    transform: rotate(2160deg);
  }
}

.logo-spinner img {
  width: 50px;
  height: 50px;
  margin-left: 12.5px;
  margin-top: 12.5px;
  animation-name: spinny;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.logo-spinner {
  width: 75px;
  height: 75px;
  border-radius: 38px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 4px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -37.5px;
  margin-top: -100px;
  z-index: 100;
  background: #fff;
}

.migration-loading-message {
  text-align: center;
  position: relative;
  top: 50vh;
  z-index: 100;
  font-weight: 100;
  font-size: 24pt;
  font-family: "Work Sans";
}

.migration-panel-account-name {
  font-family: "Work Sans";
}

.migration-panel-account-status {
  border-radius: 1rem;
  background: #f0f0f0;
  font-family: "Work Sans";
  font-size: 10pt;
  padding: 0.5em;
  line-height: 1.2em;
}

.migration-list .row {
  padding: 1em;
}

.migration-list-heading {
  position: fixed;
  width: 81vw;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 100;
}

.migration-list-heading.row {
  padding: 0em;
  cursor: pointer;
}

.migration-list-heading .col-6,
.migration-list-heading .col-4,
.migration-list-heading .col-2 {
  transition: background 100ms, border-bottom 100ms;
  padding: 1em;
}

.migration-list-heading .col-6:hover,
.migration-list-heading .col-4:hover,
.migration-list-heading .col-2:hover {
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid #00c9b2;
}

.migration-list-items {
  margin-top: 3.5em;
  border-top: 1px solid #f0f0f0;
}

.progress-col {
  padding: 0.5em 1em !important;
}

.migration-list-items > div > .row:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.12);
  position: relative;
  background: #fff;
  border: 5px solid #fff;
  cursor: pointer;
}

.migration-list-item-info {
  background: #e9ecef;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.06), inset 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.migration-progress-bar {
  position: relative;
  top: 55vh;
  padding: 1rem;
  z-index: 100;
}
