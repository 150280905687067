@import "./base";

.account-dropdown-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    margin-right: 0.5rem;
  }
}

.account-scripts-menu {
  .list-panel-item-condensed {
    padding-left: 3rem;
    cursor: pointer;
  }
  .list-panel-item-condensed-selected {
    background-color: #fff;
    font-weight: 500;
    &:hover {
      background: #f0f3f9;
      transition: 0.5s;
    }
  }
}

.scripts-title {
  color: #5a6474;
}

.script-archive-menu {
  .list-panel-item-condensed-selected {
    background-color: #e6fafc !important;
    // border: 1px solid #1fb6c1;
    color: #157178;
    font-weight: 400 !important;
  }
  .list-panel-item-condensed {
    padding-left: 6rem;
    cursor: pointer;
    display: flex;
    border: none;
    justify-content: space-between;
    align-items: center;

    .fa-trash {
      font-size: 0.7rem;

      &:hover {
        color: red;
      }
    }
  }
}

.accounts-script-explorer {
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: auto;
  max-height: 740px;
  color: #5a6474;
  .new-button {
    color: $turquoise;
    font-weight: 400;
  }

  .list-panel-item-condensed {
    &:hover {
      background: #f0f3f9;
      transition: 0.5s;
    }
  }

  .list-panel-item-condensed-selected {
    background-color: #fff;
    font-weight: 500;
    &:hover {
      background: #f0f3f9;
      transition: 0.5s;
    }
  }
}

.script-loader-container {
  .migration-loading {
    position: relative;
  }
}
